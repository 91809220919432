import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import SimpleForm from "./components/SimpleForm";
import Protected from "./Protected";
import Login from './Login';
import Navbar from "./Navbar";

function App() {
  const [isLoggedIn, setisLoggedIn] = useState(null);
  const logIn = () => {
    setisLoggedIn(true);
  };
  const logOut = () => {
    setisLoggedIn(false);
  };
  if(!isLoggedIn) {
    return <Login setisLoggedIn={setisLoggedIn} />
  }
    return (
      <BrowserRouter>
       <Navbar />
      <Routes>
        {/* <Route path='/' element={<Login />} /> */}
        <Route path='/'
          element={
          <Protected isLoggedIn={isLoggedIn}>
            <SimpleForm />
          </Protected>
        }
        />
      </Routes>
      </BrowserRouter>
      // <div>
      //   <SimpleForm />
      // </div>
    );   
}


export default App;
