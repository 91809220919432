import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { Form, Button } from 'react-bootstrap';

class SimpleForm extends Component {
	constructor() {
		super();
		this.state = {
			imageUrl: '',
			phoneNumbers: [],
			selectedTemplate: 0,
			templateName: "",
			templates: [
				{
					templateName: "",
					message: ""
				},
			]
		};
		this.sendBroadcast = this.sendBroadcast.bind(this);

		// Binding method
		// this.onFormSubmit = this.onFormSubmit.bind(this);
	}
	async componentDidMount() {
		const response = await fetch('https://api.halodesk.id/get-templates');
		const data = await response.json();
		if(data.status === 'Ok') {
			this.setState({ templates: data.data })
		}
		console.log("DATA " , data);
	}
	changeTemplate(index) {
		this.setState({ selectedTemplate: index });
	}
	sendBroadcast() {
		if(this.state.phoneNumbers.length > 400) {
			alert("Phone numbers cannot more than 400")
			return false;
		}
		const payload = 
		{
			"template_name": this.state.templates[this.state.selectedTemplate].templateName, 
			"variables": [], 
			"phone_numbers": this.state.phoneNumbers, 
		}
		if(this.state.imageUrl !== '') {
			payload.attachment_url = this.state.imageUrl
		}
		console.log("payload ", payload);
		const config = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload)
		}
		const response = fetch('https://api.halodesk.id/send-broadcast', config)
		alert("Broaadcast will be processed. Thank you")
	}

  	render() {
    return (
    <Container>
      <Form>
        <Form.Group controlId="form.Name">
            <Form.Label>Template name</Form.Label>
			<br/> 
            <Form.Select onChange={(e) => this.changeTemplate(e.target.value)} >
				{
					this.state.templates.map(({ templateName, message }, index) => {
						return <option key={index} value={index}>{ templateName }</option>;
					})
				}
			</Form.Select>
        </Form.Group>
        <Form.Group controlId="form.Textarea">
            <Form.Label>Message </Form.Label>
            <Form.Control as="textarea" rows={10} value={this.state.templates[this.state.selectedTemplate].message} disabled/>
        </Form.Group>
        <Form.Group controlId="form.Email">
            <Form.Label>Image URL (*Mandatory for media template)</Form.Label>
            <Form.Control type="text" onChange={(e) => this.setState({ imageUrl: e.target.value } )}/>
        </Form.Group>
        <Form.Group controlId="form.Textarea">
            <Form.Label>Phone numbers <b>(*comma separated value, Max 400 numbers per sending) - (*Phone numbers format 62xxxxxx)</b></Form.Label>
            <Form.Control as="textarea" rows={3} onChange={(e) => this.setState({ phoneNumbers: e.target.value.split(',') } )}/>
        </Form.Group>
		<br/>
		<Button
          className="btnFormSend"
          variant="success"
          onClick={this.sendBroadcast}
        >
          Send Broadcast
        </Button>
      </Form>
    </Container>
    );
  }
       
}

export default SimpleForm;